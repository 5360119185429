import { transparentize, lighten, darken } from 'polished';

export const white = '#ffffff';
export const black = '#343434';

export const backgroundGrey = '#F8F8F8';
export const blue = '#16A3B9';
export const brokenWhite = darken(0.02, white);
export const darkBlue = '#02A8E6';
export const darkGreen = '#46A61B';
export const darkOrange = '#EB8600';
export const darkText = '#000';
export const footer = '#6A2B68';
export const footerBorder = '#5f255d';
export const green = '#50BC1F';
export const grey = '#B4B4B4';
export const header = '#FFF';
export const headerBorder = '#e8e8e8';
export const lessTransparentWhite = 'rgba(255,255,255,0.4)';
export const lightGrey = lighten(0.25, grey);
export const lightOrange = '#E1292B';
export const lightPurple = '#8B2C7E';
export const lightText = '#FFF';
export const lighterGrey = lighten(0.15, grey);
export const middleGrey = '#bcc2c4';
export const navbarBorder = '#969696';
export const orange = '#FF9200';
export const purple = '#6A2B68';
export const purpleSocial = '#7E5C86';
export const red = '#BA1E4E';
export const transparentWhite = 'rgba(255,255,255,0.2)';

export const orangeTransparent = transparentize(0.25, lightOrange);
export const purpleTransparent = transparentize(0.25, lightPurple);

export const gradient = `linear-gradient(
  ${orangeTransparent},
  ${purpleTransparent}
)`;

export const gradientReversed = `linear-gradient(
  ${purpleTransparent},
  ${orangeTransparent}
)`;
