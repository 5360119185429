import * as ReactGA from 'react-ga';

const GAKey = 'UA-43903163-4';
const isProduction =
    process.browser &&
    process.env.NODE_ENV !== 'development' &&
    window.location.origin === 'https://werkenbij.developers.nl';

export const initGA = (): void => {
    if (isProduction) {
        ReactGA.initialize(GAKey);
    }
};

export const logPageView = (): void => {
    if (isProduction) {
        ReactGA.pageview(window.location.pathname);
    }
};

export const logModalView = (modalName): void => {
    if (isProduction) {
        ReactGA.modalview(modalName);
    }
};
